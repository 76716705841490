











































































import { computed, provide, ref, useStore, useContext } from '@nuxtjs/composition-api';
import { ELocationPickerState, IAddress, ICoordinates, IOnboardingInput, IOnboardingResult, IZoomInfo } from '~/types/app/Location';
import { ELocationSelectionState, EOnboardingState, IOnboardingState } from '~/types/app/Onboarding';
import { TranslateResult } from 'vue-i18n';
import IRootState from '~/types/app/State';
import { ZOOM_LEVEL_DETAIL } from '~/lib/constants';
import { ETrackingType, EScreenName, EComponentName } from '~/lib/segment';
import { IHub, EHubAvailabilityState } from '~/types/app/Hub';
import { signupWaitlist } from '~/api/services/signup-waitlist';
import { IDeliveryEstimate } from '~/api/services/get-delivery-estimate/get-delivery-estimate';
import LocationSelectionAddress from './LocationSelectionAddress.vue';
import LocationSelectionHub from './LocationSelectionHub.vue';
const __sfc_main = {};
__sfc_main.props = {
  hasPreviousState: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const {
    $logger,
    $apiUsers,
    $segmentEvent,
    i18n
  } = useContext();
  const store = useStore<IRootState>();
  const showAnimation = ref(false);
  const emit = __ctx.emit;

  function emitSuccess(): void {
    const result = {
      hub: hub.value,
      deliveryEta: deliveryEta.value,
      coordinates: coordinates.value ?? {
        latitude: 0,
        longitude: 0
      },
      geocodedAddress: geocodedAddress.value,
      address: transformAddress(address.value, hub.value)
    };
    emit('success', result);
  }

  const currentState = ref<ELocationSelectionState>(EOnboardingState.AddressInput);
  const addressInputState = ref<ELocationPickerState>(ELocationPickerState.AutoComplete);
  const hubAvailabilityState = ref<EHubAvailabilityState>(EHubAvailabilityState.loading);
  const state = computed<EOnboardingState>(() => {
    if (currentState.value === EOnboardingState.HubAvailabilityCheck) {
      if (hubAvailabilityState.value === EHubAvailabilityState.loading) {
        return EOnboardingState.Loading;
      }

      return hubAvailabilityState.value === EHubAvailabilityState.failure ? EOnboardingState.HubFailure : EOnboardingState.HubSuccess;
    }

    if (currentState.value === EOnboardingState.AddressInput && addressInputState.value === ELocationPickerState.Map) {
      return EOnboardingState.Map;
    }

    return EOnboardingState.AutoComplete;
  });
  provide<IOnboardingState>('onboardingState', {
    addressInputState,
    hubAvailabilityState,
    locationSelectionState: currentState,
    combinedOnboardingState: state
  });
  const isInInitialState = computed<boolean>(() => state.value === EOnboardingState.AutoComplete);
  const userInput = ref('');
  const hub = ref<IHub | undefined>();
  const deliveryEta = ref<IDeliveryEstimate | undefined>();
  const coordinates = ref<ICoordinates | undefined>();
  const geocodedAddress = ref('');
  const address = ref<IAddress | undefined>();
  const addressAddition = ref('');
  provide<IOnboardingInput>('onboardingInput', {
    userInput,
    hub,
    deliveryEta,
    coordinates,
    geocodedAddress,
    address,
    addressAddition
  });
  const currentZoom = ref<number>(ZOOM_LEVEL_DETAIL);
  const isZoomSetByUser = ref<boolean>(false);
  provide<IZoomInfo>('zoomInfo', {
    currentZoom,
    isSetByUser: isZoomSetByUser
  });

  function transformAddress(address?: IAddress, hub?: IHub): IAddress | undefined {
    if (!address) return undefined;
    const transformedAddress = { ...address
    };

    if (hub) {
      transformedAddress.country = hub.country;
    }

    return transformedAddress;
  }

  const headings = computed((): Record<EOnboardingState, TranslateResult> => {
    return {
      [EOnboardingState.Map]: i18n.t('location_onboarding_select_entrance_text'),
      [EOnboardingState.HubSuccess]: i18n.t('location_onboarding_delivery_available_text'),
      [EOnboardingState.HubFailure]: '',
      [EOnboardingState.AutoComplete]: i18n.t('location_onboarding_started_text'),
      [EOnboardingState.Loading]: '',
      [EOnboardingState.AddressInput]: '',
      [EOnboardingState.HubAvailabilityCheck]: ''
    };
  });
  const heading = computed<TranslateResult>(() => {
    return headings.value[state.value] || '';
  });

  function onOnboardingCanceled(): void {
    const trackingEventOrigin: Record<string, string> = {
      [EOnboardingState.AutoComplete]: 'address_search',
      [EOnboardingState.Map]: 'address_refinement',
      [EOnboardingState.HubSuccess]: 'address_delivery_instructions'
    };
    let eventOrigin = trackingEventOrigin[state.value];

    if (state.value === EOnboardingState.AutoComplete && userInput.value) {
      eventOrigin = 'address_search_result';
    }

    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: EScreenName.addressSearch,
      componentName: EComponentName.exitButton,
      eventOrigin
    });

    if (state.value === EOnboardingState.HubFailure && store.getters.getIsOnboardSuccess) {
      emit('cancel');
    } else if (state.value === EOnboardingState.HubFailure) {
      emit('failure');
    } else if (state.value === EOnboardingState.HubSuccess) {
      emitSuccess();
    } else {
      emit('cancel');
    }
  }

  function onBack(): void {
    switch (state.value) {
      case EOnboardingState.AddressInput:
      case EOnboardingState.AutoComplete:
        emit('go-back');
        break;

      case EOnboardingState.Map:
        showAutoComplete();
        break;

      default:
        showMap();
        break;
    }
  }

  function showMap(): void {
    resetHubAvailabilityInfo();
    currentState.value = EOnboardingState.AddressInput;
    addressInputState.value = ELocationPickerState.Map;
  }

  function showAutoComplete(): void {
    resetHubAvailabilityInfo();
    resetZoomInfo();
    coordinates.value = undefined;
    currentState.value = EOnboardingState.AddressInput;
    addressInputState.value = ELocationPickerState.AutoComplete;
  }

  function resetZoomInfo() {
    currentZoom.value = ZOOM_LEVEL_DETAIL;
    isZoomSetByUser.value = false;
  }

  function resetHubAvailabilityInfo() {
    hubAvailabilityState.value = EHubAvailabilityState.loading;
    hub.value = undefined;
    addressAddition.value = '';
  }

  function onLocationPickerSuccess(): void {
    addressInputState.value = ELocationPickerState.Success;
    hubAvailabilityState.value = EHubAvailabilityState.loading;
    currentState.value = EOnboardingState.HubAvailabilityCheck;
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: EScreenName.addressRefinement,
      componentName: EComponentName.confirmLocation
    });
  }

  function onHubAvailabilityCheckSuccessful(): void {
    if (address.value) address.value.street_address_2 = addressAddition.value;
    showAnimation.value = true;
  }

  function hideOverlay(): void {
    emitSuccess();
  }

  function onHubAvailabilityCheckFailed(): void {
    emit('failure');
  }

  function onCancel(): void {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: EScreenName.outsideDeliveryArea,
      componentName: EComponentName.browseAsGuestButton,
      componentContent: `${coordinates.value?.latitude}|${coordinates.value?.longitude}`
    });
    emit('cancel');
  }

  async function onWaitlistSignup(email: string): Promise<void> {
    if (address.value) {
      const [lang] = i18n.locale.split('-');
      await signupWaitlist($apiUsers, $logger, {
        email,
        lang,
        post_code: address.value.postal_code,
        country_code: address.value.country_code,
        city: address.value.city,
        address: address.value.street_address_1
      });
      $segmentEvent.TRACKING({
        trackingType: ETrackingType.sectionViewed,
        screenName: EScreenName.outsideDeliveryArea,
        componentName: EComponentName.joinWaitlistSuccess,
        componentContent: `${coordinates.value?.latitude}|${coordinates.value?.longitude}`
      });
      emit('waitlist-signup-success');
    }
  }

  return {
    EOnboardingState,
    showAnimation,
    emit,
    state,
    isInInitialState,
    heading,
    onOnboardingCanceled,
    onBack,
    onLocationPickerSuccess,
    onHubAvailabilityCheckSuccessful,
    hideOverlay,
    onHubAvailabilityCheckFailed,
    onCancel,
    onWaitlistSignup
  };
};

__sfc_main.components = Object.assign({
  LocationSelectionAddress,
  LocationSelectionHub
}, __sfc_main.components);
export default __sfc_main;
