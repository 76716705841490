/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { post } from '~/api/controllers/post'
import {
  EHTTPMethods,
  ELogLevels,
  EStatus,
  LoggerFunction,
} from '~/types/app/Logger'
import { AxiosError } from 'axios'

interface IPayload {
  email: string
  lang: string
  post_code?: string
  country_code?: string
  city?: string
  address?: string
}

export default async function signupWaitlist(
  client: NuxtAxiosInstance,
  logger: LoggerFunction,
  payload: IPayload
): Promise<void> {
  const url = '/waitlist/ooda/sign-up'

  try {
    await post(client, logger, url, {}, payload)
  } catch (error) {
    const e = error as AxiosError
    logger({
      level: ELogLevels.ERROR,
      msg: 'API Error: Waitlist signup failed.',
      'http.method': EHTTPMethods.POST,
      'http.url': url,
      'http.status_code': EStatus.FAILED,
      error: e.response?.data as string,
      logger: 'API Services',
      func: 'signupWaitlist',
    })
  }
}
