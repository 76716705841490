













































import { ELocationPickerState, ICoordinates, IOnboardingInput } from '~/types/app/Location';
import { EComponentName, EScreenName, ETrackingType, getScreenName } from '~/lib/segment';
import { DEFAULT_ONBOARDING_INPUT, DEFAULT_ONBOARDING_STATE } from '~/components/onboarding/LocationSelection/provide';
import { IAddressType } from '~/types/app/Tracking';
import { IMapLoaded, IOnboardingState } from '~/types/app/Onboarding';
import { inject, useContext, useRoute } from '@nuxtjs/composition-api';
import useFullAddressString from '~/hooks/onboarding/useFullAddressString';
import LocationPickerMap from '~/components/onboarding/LocationPickerMap/LocationPickerMap.vue';
import LocationAutoComplete from '~/components/onboarding/LocationSelection/LocationAutoComplete.vue';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  searchboxLabel: {
    type: String,
    required: true
  },
  heading: {
    type: String,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const {
    $segmentEvent
  } = useContext();
  const route = useRoute();
  const {
    addressInputState
  } = inject<IOnboardingState>('onboardingState', DEFAULT_ONBOARDING_STATE);
  const {
    userInput,
    address,
    coordinates
  } = inject<IOnboardingInput>('onboardingInput', DEFAULT_ONBOARDING_INPUT);
  const fullAddressString = useFullAddressString({
    userInput,
    address
  });

  function trackMapViewed({
    map,
    hasUserSpecifiedEntrance
  }: IMapLoaded) {
    const center = map.value?.getCenter();

    if (hasUserSpecifiedEntrance?.value || !center) {
      return;
    }

    const screenName = getScreenName(route.value);
    const addressType = coordinates.value ? IAddressType.KNOWN : IAddressType.UNKNOWN;
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.screenViewed,
      screenName: EScreenName.addressRefinement,
      componentContent: `${center.lat()}|${center.lng()}`,
      eventOrigin: `${screenName}|${addressType}`
    });
  }

  function onLocationPickerMapDragend(distanceMeters: number): void {
    emit('map-dragend', distanceMeters);
  }

  function trackPinMovedTooFar(): void {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.sectionViewed,
      screenName: EScreenName.addressRefinement,
      componentName: EComponentName.locationPinExceededDistanceText,
      eventOrigin: getScreenName(route.value)
    });
  }

  function onMapPicked(newCoordinates: ICoordinates): void {
    coordinates.value = newCoordinates;
    emit('success');
  }

  function handleResultChosen(): void {
    addressInputState.value = ELocationPickerState.Map;
  }

  return {
    ELocationPickerState,
    emit,
    addressInputState,
    address,
    coordinates,
    fullAddressString,
    trackMapViewed,
    onLocationPickerMapDragend,
    trackPinMovedTooFar,
    onMapPicked,
    handleResultChosen
  };
};

__sfc_main.components = Object.assign({
  LocationAutoComplete,
  LocationPickerMap
}, __sfc_main.components);
export default __sfc_main;
