import { render, staticRenderFns } from "./LocationSelection.vue?vue&type=template&id=2bf034ae&scoped=true&"
import script from "./LocationSelection.vue?vue&type=script&lang=ts&"
export * from "./LocationSelection.vue?vue&type=script&lang=ts&"
import style0 from "./LocationSelection.vue?vue&type=style&index=0&id=2bf034ae&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bf034ae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OnboardingModalBackButton: require('/app/build/components/onboarding/OnboardingModalBackButton/OnboardingModalBackButton.vue').default,LocationSelectionAddress: require('/app/build/components/onboarding/LocationSelection/LocationSelectionAddress.vue').default,LocationSelectionHub: require('/app/build/components/onboarding/LocationSelection/LocationSelectionHub.vue').default,VModal: require('/app/build/components/VModal/VModal.vue').default,SuccessCard: require('/app/build/components/SuccessCard/SuccessCard.vue').default})
